.customDropdown {
    & button {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: #0d6efd;
        padding: 0.5rem 1rem;
        border-right: none !important;
    }
    &.active {
        border-radius: 0.25rem;
        background-color: #0d6efd;
        & button {
            color: #fff;
        }
    }
}

.itemWrapper {
    display: flex;
    align-items: center;

    div {
        margin-left: 10px;
    }
}

.profileBlock {
    display: flex;
    flex-direction: column;
    line-height: 18px;
}
