@font-face {
    font-family: "Glyphicons Halflings";
    src: url("./fonts/glyphicons-halflings-regular.eot");
    src: url("./fonts/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype"),
    url("./fonts/glyphicons-halflings-regular.woff2") format("woff2"),
    url("./fonts/glyphicons-halflings-regular.woff") format("woff"),
    url("./fonts/glyphicons-halflings-regular.ttf") format("truetype"),
    url("./fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular") format("svg");
}

header, main, footer {
    padding-left: calc(100vw - 100% + 10px) !important;
}

#footerID {
    padding-left: calc(100vw - 100% + 10px) !important;
}

html, body {
    font-size: 14px !important;
    min-width: 992px;
}

body {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

.nav-link {
    padding: 0.5rem 0.9rem !important;
}

.showHidePassIcon {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding: 1rem 0.75rem;

    &.is-invalid {
        right: 20px;
    }
}

.validation-error {
    margin-bottom: 10px;
    color: red
}

.selectBlock {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 15px !important;
    margin-bottom: 10px !important;
    align-items: center;
}

.subSelectBlock {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.searchSelect {
    min-width: fit-content;
}

.custom-link-table-item {
    text-decoration: none;
    color: #000;

    &:hover {
        text-decoration: underline;
    }
}

.backButton {
    display: flex;
    justify-content: flex-start;
    width: fit-content;
}

.backButtonForm {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

button.btn:focus {
    outline: none;
    box-shadow: none;
}

.form-buttons-block {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 20px;
}

.page-item .page-link { //pagination
    z-index: 0 !important;
}

.clearInputButton {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding-top: 2px;
}

.formSignin {
    width: 100%;
    max-width: 430px;
    padding: 15px;
    margin: auto;
}

.formInputs {
    max-width: 300px;
    margin: auto;
}

.formSignin .checkbox {
    font-weight: 400;
}

.hoverMapText {
    position: absolute;
    top: 5px;
    z-index: 20;
    opacity: 0.9;
    padding: 10px;
    border-radius: 10px;
    font-weight: bold;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

    &:hover {
        opacity: 1;
        background-color: #ffffff;
    }
}

